import React from 'react';
import { Link, graphql } from 'gatsby';
import { localizer } from '../util/localizer';
import { Helmet } from 'react-helmet';
import Footer from '../components/FooterSimple';
import { setLinks } from '../util/pagetools';
import logo from '../images/logo.png';

export default function ErrorPage(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const sitedata = props.data.siteYaml;
  // const errorCfg = (props.location ? (props.location.state || {}) : {});
  const backHome = localized('/');

  const toplinks = setLinks(sitedata.sections);
  const footdata = {
    ...sitedata.footer,
    toplinks,
    sitename: sitedata.sitename
  };

  return (
    <div id="page">
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>{sitedata.sitename}</title>
      </Helmet>
      <div className="layout-root">
        <div className="thank-you">
          <div id="logo">
            <Link to={backHome}>
              <img src={logo} alt={sitedata.sitename} className="img-responsive" />
            </Link>
          </div>
          <h1 className="my-8 p-8">{sitedata.errorpage.appError}</h1>
          <div className="mt-160">
            <Link to={backHome} className="std-btn btn-white-on-light">OK</Link>
          </div>
        </div>
        <Footer src={footdata} localizerfn={localized} />
      </div>
    </div>
  );
};

export const query = graphql`
  query siteErrorQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      sitename
      locale
      variation
      errorpage {
        appError
      }
      sections {
        title
        linkto
      }
      footer {
        text
        links {
          label
          to
        }
      }
    }
  }`;
